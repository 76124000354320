<template>
    <Header :title="$tc('energy_data', 1)" heading="h1" />
    <section class="mt-10">
        <TabMenu :links="menuLinks" />
    </section>
    <section class="mt-5">
        <router-view />
    </section>
</template>

<script>
    import TabMenu from '@/components/general/TabMenu';

    export default {
        components: {
            TabMenu,
        },
        // created() {
        //     this.$store.dispatch('energy_data/fetchElecEndexData').then(() => (this.isLoaded = true));
        // },
        data() {
            return {
                menuLinks: [
                    { to: { name: 'electricityData' }, display_name: this.$tc('electricity', 1) },
                    { to: { name: 'gasData' }, display_name: this.$tc('gas', 1) },
                    { to: { name: 'emissionData' }, display_name: this.$tc('emission', 2) },
                ],
                isLoaded: false,
            };
        },
    };
</script>
